import React from 'react';
import Snowfall from 'react-snowfall';

const snowflake = document.createElement('div');
snowflake.innerText = '❄️'; // snowflake emoji
snowflake.style.fontSize = '1.2rem';

export default function Snow() {
  return (
    <Snowfall
      snowflakeCount={120}
      color="white"
      style={{ position: 'fixed', width: '100vw', height: '100vh', zIndex: 999 }}
      radius={[1, 6]}
      speed={[0.5, 2]}
      wind={[-0.5, 2]}
    />
  );
}
