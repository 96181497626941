import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // for accessibility

export default function GalleryLightbox({ isOpen, onClose, imageSrc, alt }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center z-[1000] outline-none"
      overlayClassName="fixed inset-0 bg-black/80 z-[999]"
    >
      <div className="relative max-w-3xl w-full mx-auto p-4">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white text-2xl hover:text-red-500"
        >
          ✕
        </button>
        <img
          src={imageSrc}
          alt={alt}
          className="w-full rounded-xl shadow-xl"
        />
      </div>
    </Modal>
  );
}
